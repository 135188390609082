<template>
  <TMediaPlanning />
</template>

<script>
import { TMediaPlanning } from "@templates";

export default {
  name: "PMediaPlanning",
  components: { TMediaPlanning },
};
</script>

<style lang="scss" scoped></style>
